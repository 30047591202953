<template>
<div>
  <div class="card card-outline card-primary">
      <div class="card-header">
          <h2 class="card-title">Erstattungen</h2>
          
          <div class="card-tools">
              <!-- <button class="btn btn-sm btn-primary" @click="openModal">Neue Erstattung</button> -->
          </div>
      </div>

      <div class="card-body">
        <table class="table table-striped table-sm table-bordered" v-if="order.refunds.length > 0">
            <thead class="thead-light">
                <th>Datum</th>
                <th>Betrag</th>
                <th>Methode</th>
                <th>Status</th>
                <th>Aktion</th>
            </thead>

            <tbody>
                <tr v-for="(refund,index) in order.refunds" :key="refund.id">
                    <td>{{ refund.created_at | formatDateTime }}</td>
                    <td>{{ refund.amount | toCurrency }}</td>
                    <td>{{ generateMode(refund.mode) }}</td>
                    <td>
                        <div v-if="selectedRow[index] && selectedCell === 'status'" class="input-group">
                            <select class="form-control form-control-sm" v-model="order.refunds[index].status_id" @change="changeStatus(index)" v-if="$auth.check('orders.edit')">
                                <option v-for="state in refundStates" :key="state.id" :value="state.id">{{ state.description }}</option> 
                            </select>
                            <div class="input-group-append">
                                <!-- <button class="btn btn-sm btn-primary" type="button" @click="changeStatus('order', index)"><i class="fas fa-save"></i></button> -->
                                <button class="btn btn-sm btn-secondary" type="button" @click="handleEditCell(index, 'status')"><i class="fas fa-times"></i></button>
                            </div>
                        </div>
                        <span @dblclick="handleEditCell(index, 'status')" v-else class="badge" :class="setStatusBadge(refund.state.name)">{{ refund.state.description }}</span>
                        
            
                    </td>
                    <td>
                        <!-- <button class="btn btn-sm btn-success"><i class="fas fa-download"></i></button> -->
                        <a v-if="refund.storno != null" class="btn btn-sm btn-success" target="_blank" :href="url + '/api/v1/download/' + refund.storno.correction.type.key + '/' +  refund.storno.correction.hash"><i class="fas fa-download"></i></a>
                    </td>
                </tr>
            </tbody>
        </table>

        <div v-else>
            <h5>Es liegen noche keine Erstattungen für diese Bestellungen vor</h5>
        </div>


    </div>
  </div>


<b-modal id="refundModal" title="Erstelle ein neue Erstattung" ok-only ok-variant="danger" ok-title="Speichern" size="sm" @ok="handleOk">

        <div class="form-group">
            <label for="name" class="control-label">Modus*</label>
            <select v-model="mode" class="form-control form-control-sm">
                <option v-if="order.chargeId != null" value="amazon">Amazon</option>
                <option v-if="order.paypal_order_id != null" value="paypal">PayPal</option>
                <!-- <option v-if="order.ingenico_refundId != null" value="paypal">PayPal</option> -->
                <!-- <option value="voucher">Gutschein</option> -->
                <option value="bank">Überweisung</option>
            </select>
        </div>


        <div class="form-group">
            <label for="name" class="control-label">Wert*</label>
            <input v-model="amount" type="text" class="form-control form-control-sm">
        </div>

</b-modal>

</div>
</template>

<script>
export default {
    name: "RefundsComponent",

    props: [
        'order'
    ],

    data(){
        return{
            url: process.env.VUE_APP_DOWNLOAD_URL,
            selectedRow: {},
            selectedCell: null,
            refundStates: {},
            mode: 'bank',
            amount: '0.00',
        }
    },

    methods:{
        changeStatus(order)
        {
            var orders = [this.order.refunds[order].id];
           
            this.axios
                .post("/refunds/changeStatus", {
                    orders: orders,
                    status_id: this.order.refunds[order].status_id,
                })
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Status erfolgreich geändert!",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                        });
                    this.handleEditCell(order, 'status')
                    this.$parent.getOrder();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        getRefundStates(){
            this.$Progress.start();
            this.axios
                .get("/states/refund")
                .then((response) => {
                    this.refundStates = response.data.data;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        handleEditCell(index, name) {
            this.selectedCell = name;
            this.selectedRow = {
            [index]: !this.selectedRow[index]
            }
        },
        
        createRefund(){
            this.axios
                .post("/refunds", {
                    'order_id': this.order.id,
                    'mode': this.mode,
                    'amount': this.amount,
                })
                .then(() => {
                    this.$parent.getOrder();
                    this.$bvModal.hide("refundModal");
                    this.$swal({
                        icon: "success",
                        title: "Erstattung wurde hinzugefügt",toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$Progress.finish();
                    
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                    this.$Progress.fail();
                });
        },
        
        handleOk(bvModalEvt){
            bvModalEvt.preventDefault()
            this.createRefund()
        },

        openModal(){
            this.$bvModal.show("refundModal");
        },

        generateMode(mode){
            if(mode == 'amazon'){
                return 'Amazon Pay'
            }
            
            if(mode == 'card'){
                return 'Kreditkarte'
            }
            if(mode == 'card_ingenico'){
                return 'Kreditkarte'
            }

            if(mode == 'paypal'){
                return 'Paypal'
            }

            if(mode == 'voucher'){
                return 'Gutschein'
            }

            if(mode == 'bank'){
                return 'Überweisung'
            }
        },

        setStatusBadge(state){
            if(state == 'open')
            {
                return 'badge-light'
            }

            if(state == 'completed')
            {
                return 'badge-success'
            }

            if(state == 'canceled')
            {
                return 'badge-danger'
            }

            return 'badge-primary';
        }
    },

    created() {
        this.getRefundStates();
    }
    

}
</script>

<style>

</style>