<template>
<div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-primary">
          <div class="card-header">
            <h2 class="card-title">Änderungsverlauf</h2>
          </div>

          <div class="card-body">
            <table class="table table-striped table-sm table-bordered">
              <thead class="thead-light">
                <th>Datum</th>
                <th>Name</th>
                <th>Feld</th>
                <th>Alt</th>
                <th style="width: 50%">Neu</th>
              </thead>

              <tbody>
                <template v-for="activity in order.activities">
                    <tr v-for="change in activity.changes" :key="change.date">
                      <template v-if="change.field == 'Bildnachweise'">
                        <td>{{ activity.date | formatDateTime }}</td>
                        <td>{{ activity.username }}</td>
                        <td>{{ change.field }}</td>
                        <td>{{ change.old }}</td>
                        <td>
                          <div class="row">
                            <div class="col-md-2 text-center p-1" v-for="(photo, index) in change.new.photos" :key="index">
                              <img :src="photo" style="max-width: 100%; height: auto; width:70%">
                            </div>
                          </div>
                        </td> 
                      </template>
                      <template v-else>
                        <td>{{ activity.date | formatDateTime }}</td>
                        <td>{{ activity.username }}</td>
                        <td>{{ change.field }}</td>
                        <td>{{ change.old }}</td>
                        <td>{{ change.new }}</td>
                      </template>
                    </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name: 'OrderDetailsHistory',
    props: [
        'order'
    ],
    data(){
        return  {
        }
    },
}
</script>