<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-primary">
          <div class="card-header">
            <h2 class="card-title">Generierte Dokumente</h2>
            <div class="card-tools" v-if="order.invoice == null">
              <button class="btn btn-primary btn-sm" @click="generateInvoice()">Rechnung erzeugen</button>
            </div>
          </div>

          <div class="card-body">
            <table class="table table-striped table-sm table-bordered">
              <thead class="thead-light">
                <th>Datum</th>
                <th>Name</th>
                <th>Betrag</th>
                <th>DATEV Export Datum</th>
                <th>Aktionen</th>
              </thead>

              <tbody>
                <tr v-for="document in order.documents" :key="document.id">
                  <td>{{ document.created_at }}</td>
                  <td><a href="#" @click="downloadDocument(document)">{{ document.document.name }} {{ document.number}}</a></td>
                  <td>{{ document.amount | toCurrency }}</td>
                  <td>-</td>
                  <td>
                    <!-- <button class="mr-1 btn btn-danger btn-sm"><i class="fas fa-trash"></i></button>        -->
                    <button v-if="order.rsu == null" v-b-popover.hover.top="'E-Mail senden'" class="mr-1 btn btn-info btn-sm" @click="sendMail(document)"><i class="fas fa-envelope"></i></button>
                    <button v-b-popover.hover.top="'Neu generieren'" v-if="document.document.key == 'picklist' || document.document.key == 'invoice' || document.document.key == 'correction'" class="btn btn-success btn-sm" @click="updateDocument(document.id)"><i class="fas fa-redo"></i></button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <b-modal :no-enforce-focus="true" id="mailDocumentModal" scrollable title="E-Mail an den Kunden senden" ok-variant="primary" ok-title="E-Mail senden" cancel-title="Abbrechen" size="xl" @ok="sendNotification">
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="">An:</label>
                    <select class="form-control form-control-sm" multiple v-model="selected_emails">
                      <option v-for="email in emails" :key="email" :value="email">{{ email }}</option>
                    </select>
                </div>

                <div class="form-group">
                    <label for="">Betreff:</label>
                    <input type="text" class="form-control form-control-sm" v-model="subject" />
                </div>

                <div class="form-group">
                  <editor
                                v-bind:api-key="this.$tinyApiKey"
                                :init="{
                                    height: 400,
                                    menubar: true,
                                    plugins: [
                                    'code advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar:
                                    'undo redo | formatselect | bold italic backcolor | \
                                    alignleft aligncenter alignright alignjustify | \
                                    bullist numlist outdent indent | removeformat | code | help'
                                }"
                                v-model="message"
                              
                                />
                </div>
            </div>
        </div>

        <template #modal-footer="{ ok, cancel}">
          <b-button size="sm" variant="light" @click="cancel()">
            Abbrechen
          </b-button>
          <b-button size="sm" variant="primary" @click="ok()" :disabled="selected_emails.length <= 0">
            Senden
          </b-button>
        </template>
    </b-modal>
  
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
// import ClassicEditor from '../../../assets/packages/ckeditor5/src/ckeditor';
import "tinymce/tinymce";
// import "./../../../assets/packages/tinymce-bootstrap";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";
import "tinymce/plugins/advlist";
import "tinymce/plugins/autolink";
import "tinymce/plugins/bootstrap";
import "tinymce/plugins/code";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/lists";
import "tinymce/plugins/charmap";
import "tinymce/plugins/print";
import "tinymce/plugins/preview";
import "tinymce/plugins/help";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/table";


export default {
  name: 'OrderDetailsDocuments',
  props: [
      'order'
  ],

  data(){
    return {
      url: process.env.VUE_APP_DOWNLOAD_URL,
      document_type: 'invoice',
      message: "",
      subject: "",
      document_id: "",
      email: "",
      emails: [],
      selected_emails: [],
      editor: Editor,
      editorConfig: {
          simpleUpload: {
              uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
              withCredentials: true,
              headers: {
                  'Accept': 'application/json',
                  'Authorization': 'Bearer '+ this.$auth.token()
              }
          }
      },
    }
  },

  methods:{
    sendNotification(bvModalEvt){
      bvModalEvt.preventDefault();
      this.axios
          .post("/mail/send", {
              'emails': this.selected_emails,
              'subject': this.subject,
              'content': this.message,
              'company_id': this.order.company.id,
              'documents': [this.document_id],
          })
          .then(() => {
              this.$bvModal.hide('mailDocumentModal');
              this.selected_emails = [];
              this.$swal({
                  icon: "success",
                  title: "E-Mail gesendet",
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: false,
                  timer: 2000,
                  timerProgressBar: true,
                  });
          })
          .catch(() => {
              this.$swal({
                  icon: "error",
                  title: "Oops...",
                  text: "Da ist etwas schief gelaufen",
              });
          });
    },

    sendMail(document){
      this.document_id = '';
      this.document_id = document.id;
      this.$Progress.start();
      this.axios
          .post("/orders/mail/send", {
              'order_id': this.order.id,
              'company_id': this.order.company.id,
              'type': 'documents',
              'template': document.document.key,
          })
          .then((response) => {
              this.$Progress.finish();
              this.message = response.data.data;
              if(document.document.key == 'invoice')
              {
                this.subject = 'Ihre Bestellung bei ' + this.order.company.name;
              }
              else
              {
                this.subject = 'Deine ' + document.document.name + ' vom ' + this.order.company.name;
              }
              this.$bvModal.show('mailDocumentModal')
          })
          .catch(() => {
              this.$swal({
                  icon: "error",
                  title: "Oops...",
                  text: "Da ist etwas schief gelaufen",
              });
              this.$Progress.fail();
          });
    },

    createDocument(){

    },

    updateDocument(id)
    { 
      this.$Progress.start();
      this.axios
          .put("/orders/documents/" + id, {
          })
          .then(() => {
              // this.billingStates = response.data.data;
              this.$swal({
                                    icon: "success",
                                    title: "Dokument wurde neu generiert",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                    });
              this.$parent.getOrder();
              this.$Progress.finish();
          })
          .catch(() => {
              this.$swal({
                  icon: "error",
                  title: "Oops...",
                  text: "Da ist etwas schief gelaufen",
              });
              this.$Progress.fail();
          });
    },

    downloadDocument(document){
        this.axios
            .get("/download/" + document.document.key +"/" + document.hash, {responseType: 'arraybuffer'})
            .then(response => {
              // console.log(response.data)
              this.downloadFile(response, document.number)
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufennnnn",
                });
            });
    },

    generateInvoice() {
      this.axios
        .post('/orders/documents', {
          order_id: this.order.id
        })
        .then(() => {
          this.$swal({
            'icon': 'success',
            'title': 'Rechnung wurde erzeugt',
            'text': 'Die Rechnung wurde erfolgreich erzeugt' 
          })
          this.$parent.getOrder();
        })
        .catch(() => {
          this.$swal({
            'icon': 'error',
            'title': 'Oops...',
            'text': 'Da ist etwas schief gelaufen' 
          })
        })
    },

    downloadFile(response, filename){
        var newBlob = new Blob([response.data], {type: 'application/pdf'})

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob)
          return
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob)
        var link = document.createElement('a')
        link.href = data
        link.download = filename + '.pdf'
        link.click()
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data)
        }, 100)
    },
  },

  mounted(){
    this.emails.push(this.order.customer.email);
      if(this.order.customer.invoice_email != null)
      {
        this.emails.push(this.order.customer.invoice_email);
      }
  }

}
</script>

<style>
.ck-editor__editable {
    min-height: 500px;
    max-height: 500px;
}
</style>